<!-- Footer -->
<div class="container-fluid footbginner">
</div>
<div class="footbg">
  <div class="container-fluid py-3">
    <div class="row mx-auto">
      <!-- This is the coloumn-1 (start) -->
      <div class="col-md-4">
        <h3 class="text-white  divdrFtr">About Us</h3>
        <p class="text-justify text-white mt-4">With over 10 years of experience Tayseer Technologies In Hyderabad is
          specialized in software development, Web Design & Development, E-Commerce, Mobile Friendly, Mobile App etc..
        </p>
        <p class="text-justify text-white">
          Since our establishment, we've been innovating the digital solutions and web apps industries, resulting in
          over 150+ successful projects.</p>
        <div class="mt-2 mb-2">
          <a routerLink="/about-us" class="btn btn-outline-light">Readmore</a>
        </div>
        <div class=" my-3 hideonmobile">
          <a target="_blank" href="https://www.facebook.com/Tayseer-Technologies-146401190872329/"
            class="btn btn-outline-light smediaIcons mx-1"><i class="fab fa-facebook "></i></a>
          <a target="_blank" href="https://www.linkedin.com/company/tayseer-technologies"
            class="btn btn-outline-light  smediaIcons mx-1"><i class="fab fa-linkedin "></i></a>
          <!-- <a href="#" class="btn btn-outline-light smediaIcons mx-1"><i class="fab fa-twitter  "></i></a> -->
          <a target="_blank" href="https://www.instagram.com/tayseer_technologies/"
            class="btn btn-outline-light smediaIcons mx-1"><i class="fab fa-instagram "></i></a>
        </div>
      </div>
      <!-- This is the coloumn-1 (end) -->
      <!-- This is the coloumn-2 (start) -->
      <div class="col-md-5">
        <h3 class="text-white  divdrFtr">Quick links</h3>
        <!-- <span ><hr class="dividerColor w-25" /></span> -->

        <div class="list-group mt-4">
          <ul class="quickLinks ">
            <li class="list-group-item-action"><a class="text-dark" routerLink="/web-design-development">Web
                Designing & Development</a></li>
            <hr />
            <li class="list-group-item-action"><a class="text-dark" routerLink="/seo">SEO & SMO</a></li>
            <hr />
            <li class="list-group-item-action"> <a class="text-dark" routerLink="/googleadwords">Google Adwords
                & Social Media </a></li>
            <hr />
            <li class="list-group-item-action"> <a class="text-dark" routerLink="/graphic-designing"> Graphic
                Design & Branding </a></li>
            <hr />
            <li class="list-group-item-action"><a class="text-dark" routerLink="/contact-us"> Contact Us </a>
            </li>
          </ul>
        </div>
        <!-- This is the coloumn-3 (end) -->
      </div>
      <div class="col-md-3">
        <img class="rounded " src="../../assets/images/tayseer-icon.png" alt="Tayseer Technologies Logo" width="200" />
        <h2 class="font-weight-bold undrLnd"><a href="tel:04040140473"><u> 040-40140473</u></a></h2>
        <h2 class="font-weight-bold undrLnd"><a href="tel:9701324167"><u>91-9701324167</u></a></h2>
      </div>
    </div>
    <!--main row end-->
  </div>
  <!--main container end-->
</div>

<!-- LowerFooter start  -->
<div class="container-fluid text-white lowerfooterBg">
  <div class="row modal-footer">
    <h6 class="text-center display-8">
      © 2021 Tayseer Technologies. All rights reserved | Design by Tayseer
      Technologies.
    </h6>
  </div>
</div>
<!-- LowerFooter end  -->