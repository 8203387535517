import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }

  // sendHttpPostRequest(endpoint: string, payload: any): Promise<any> {
  //   return new Promise<any>((resolve, reject) => {
  //     const headers = new Headers({ 'Content-Type': 'application/json' });
  //     options = options ? options : new RequestOptions({ headers: headers });
  //     const endPointUrl = environment.apiProtocol + '://' + environment.apiServerIP + '/api/' + endpoint;
  //     this.http.post(endPointUrl, JSON.stringify(payload), options)
  //       .toPromise()
  //       .then(response => {
  //         const jsonResponse = response.json();
  //         resolve(jsonResponse);
  //       })
  //       .catch(error => {
  //         reject(error);
  //       });
  //   });
  // }
}
