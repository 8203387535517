import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ApiService } from './shared/api.service';
import { AppdataService } from './shared/appdata.service';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    PageNotFoundComponent,
      
    

  ],
  imports: [
    BrowserModule,
    // tslint:disable-next-line: deprecation
    AppRoutingModule, FormsModule, ReactiveFormsModule 
  ],
  providers: [ApiService, AppdataService],
  bootstrap: [AppComponent]
})
export class AppModule { }
