<div class="container-fluid bg-brown top-bar">
    <div class="row">  
        <div class="col-md-6"> 
            <a  href ="tel:+919701324167"><i class="fa fa-phone-square"></i> +91-9701324167</a> <span class="px-3"> | </span>  
             <a  href ="tel:04040140473"><i class="fa fa-phone"></i> 040-40140473</a><span class="px-3"> | </span> 
             <a  href ="mailto:info@tayseertech"><i class="fa fa-envelope"></i> info@tayseertech.com</a>
        </div>
        <div class="col-md-6 text-right hideonmobile">
            <a target="_blank" target="blank" href  ="https://www.facebook.com/Tayseer-Technologies-146401190872329/"><i class="fab fa-facebook"></i></a> <span class="px-2"></span>
            <a target="_blank" href ="https://www.linkedin.com/company/tayseer-technologies"><i class="fab fa-linkedin"></i></a> <span class="px-2"></span>          
            <!-- <a target="blank" href ="#"><i class="fab fa-twitter"></i></a> <span class="px-2"></span> -->
            <a target="_blank" href ="https://www.instagram.com/tayseer_technologies/"><i class="fab fa-instagram"></i></a> <span class="px-2"></span>
           
        </div>
    </div>
</div>

<!--navigation-->  
<nav class="navbar navbar-expand-lg navbar-light">
    <a class="navbar-brand"><img src="../../assets/images/svg/Tayseertechnologies-weblogo.svg " alt="tayseer technologies pvt ltd logo "></a>
    <button  (click)="toggleNavbar()" class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>

    <div  [ngClass]="{'show': navbarOpen }"  class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ml-auto">
            <li class="nav-item " >
                <a class="nav-link animateDividr "  routerLink="/home" routerLinkActive="router-link-active">Home</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/about-us" routerLinkActive="router-link-active">About Us</a>
            </li>
            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false" 
                    [class.router-link-active]="router.isActive('/seo', true) || router.isActive('/googleadwords', true) || router.isActive('/graphic-designing', true) ||router.isActive('/web-design-development', true)"
                      >  Our Services
                 </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a class="dropdown-item" routerLink="/web-design-development">Web Design & Development</a>
                    <!-- <a class="dropdown-item " target="blank" href ="#">Theme Development</a> -->
                    <a class="dropdown-item" routerLink="/googleadwords">Google Adwords & Social Media </a>
                    <a class="dropdown-item" routerLink="/seo">SEO & SMO</a>
                    <a class="dropdown-item" routerLink="/graphic-designing">Graphic Design & Branding</a>
                </div>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/career" routerLinkActive="router-link-active">Career</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/contact-us" routerLinkActive="router-link-active">Contact Us</a>

            </li>
        </ul>
        <form class="form-inline my-2 my-lg-0">
            <a class="btn btn-brown my-2 my-sm-0" 
                target="blank" href="https://wa.me/919701324167?text=I'm%20interested%20in%20one%20of%20your%20services,%20call%20me%20back,%20Thanks.">WHATSAPP
                US <i class="fab fa-whatsapp"></i></a>
        </form>
    </div>
</nav>




<!--navigation End-->