
<div class="container-fluid">
    <div class="row">
        <div class="col-md-12 bg-light">
            <h2 class="text-center text-dark my-5">Page Not Found</h2>
        </div>
        <div class="col-md-12 mx-auto text-center my-3">
            <img   routerLink="/" class="img-fluid" src="../../assets/images/pagenotfound.png" alt="Page not found -Tayseer Technologies" width="600">
        </div>
        <div class=" col-md-12 text-center mt-3">
            <a class="btn btn-primary btn-lg" href="" routerLink="/">Home</a>
        </div>
    </div>
</div>  