import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AppdataService {

  constructor(private apiService: ApiService) { }

  // sendEmail(subject: string, contactName: string, contactPhone: string, contactEmail: string, contactMessage: string): Promise<any> {
  //   return new Promise<any>((resolve, reject) => {
  //     const endpoint = 'sendemail.php';
  //     const payload = {subject, name: contactName, phone: contactPhone, email: contactEmail, message: contactMessage};
  //     this.apiService.sendHttpPostRequest(endpoint, payload)
  //       .then(resp => {
  //         resolve(resp);
  //       })
  //       .catch(err => {
  //         reject(err);
  //       });
  //   });
  // }
}
