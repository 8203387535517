import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: '',
   
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'about-us',
    loadChildren: () => import('./about-us/about-us.module').then(m => m.AboutUsModule),
  },
 
  {
    path: 'web-design-development',
    loadChildren: () => import('./web-design-development/web-design-development.module').then(m => m.WebDesignDevelopmentModule),
  },
  {
    path: 'graphic-designing',
    loadChildren: () => import('./graphic-designing/graphic-designing.module').then(m => m.GraphicDesigningModule),
  },
  {
    path: 'seo',
    loadChildren: () => import('./seo-smo/seo-smo.module').then(m => m.SeoSmoModule),
  },
  {
    path: 'googleadwords',
    loadChildren: () => import('./google-adwords/google-adwords.module').then(m => m.GoogleAdwordsModule),
  },
  {
    path: 'career',
    loadChildren: () => import('./career/career.module').then(m => m.CareerModule),
  },
  {
    path: 'career/fullstack',
    loadChildren: () => import('./career/fullstack/fullstack.module').then(m => m.FullstackModule),
  },
  
  { path: 'career/androiddeveloper', 
  loadChildren: () => import('./career/androiddeveloper/androiddeveloper.module').then(m => m.AndroiddeveloperModule) },

  { path: 'career/iosdeveloper', 
  loadChildren: () => import('./career/ios-developer/ios-developer.module').then(m => m.IosDeveloperModule) },

  {
    path: 'contact-us',
    loadChildren: () => import('./contact-us/contact-us.module').then(m => m.ContactUsModule),
  },
  {
     path: '**', component: PageNotFoundComponent 
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', scrollPositionRestoration: 'enabled', initialNavigation: 'enabled' })],
  exports: [RouterModule]
})


export class AppRoutingModule { }
